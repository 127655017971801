import React from "react"

import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import Button from '../components/btn'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from '../components/form'
import Slider from '../components/slider'
import Box from '../components/box'
import Hr from '../components/hr'
import { FaHome, FaDesktop, FaMobileAlt, FaChartLine, FaBong, FaChalkboardTeacher } from 'react-icons/fa';
import {IoIosArrowDroprightCircle} from 'react-icons/io';
import { GoDashboard } from 'react-icons/go';
import Clients from '../components/clients'
import styled from 'styled-components'
import PageTitle from '../components/page-title'
import Img from 'gatsby-image/withIEPolyfill'
import { FaCode } from 'react-icons/fa'

let StyledImg = styled(props => <Img {...props}/>)`
  perspective: 1500px;
  perspective-origin: left center;
  overflow: visible !important;
  picture, img {
    transform: rotateY(-35deg) rotateX(15deg);
    box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2);
    border-radius: .625rem;
    transition: 1s !important;
    &:hover {
      transform: rotateY(-30deg) rotateX(15deg);
    }
  }
`

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

const Background = styled.div`
  background: #01b6ca;
  color: white;
  padding: 5rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  text-align: center;
`

let Service = ({title, Icon = IoIosArrowDroprightCircle, content}) => (
  <div className="d-flex mb-4">
    <div><IoIosArrowDroprightCircle size={30} className="text-primary"/></div>
    <div className="ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

let Tool = ({title, icon, content}) => (
  <div className="d-flex mb-5">
    <img src={icon} class="text-primary" width="75px"/>
    <div className="ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

export default () => (
  <Layout>
    <SEO title="Reporting &amp; Analysis | Empathy | Data agency" description="Empathy help business make better digital presence with data. We review your website or mobile app via variety of tools, analyze click stream data, heatmaps &amp; media performance data to give recommendations on improving your digital perfromance." keywords="web analytics, app analytics, Google Analytics, Adobe Analytics, Firebase, AppsFlyer, reporting, analysis"/>
    <Background>
      <h2>"If you torture the data long enough, it will confess."</h2>
      <p>Ronald Coase - economist, Nobel Prize winner</p>
    </Background>
    <Container className="pt-4">
      <div className="text-center">
        <h1>Wesbite Audit &amp; Analysis</h1>
        <p className="text-muted">Deep dive into data to look for actionable insights.</p>
      </div>
    </Container>
    <Container className="pt-4">
      <Row>
        <Col>
          <Service title="Customer acqusition analysis" content="Review your promotional channel (e.g. email, paid search, social) data such as spend, clicks &amp; suggest optimization."/>
          <Service title="Customer experience analysis" content="Analyze customers' click &amp; navigation patterns and task completion to give UX recommendation."/>
          </Col>
        <Col>
          <Service title="Content engagement analysis" content="Compare content consumption data (e.g. time spent, % scroll) to priortize content creation ideas."/>
          <Service title="Customer feedback review" content="Review &amp; summerize customer feedback to understand your customers' opinion on your offering."/>
        </Col>
      </Row>  
    </Container>   
    <Container className="py-4">
      <h2 className="text-primary mb-2">Platforms We Use</h2>
      <p class="mb-5">We leverage a variety of platforms to analyze your digital properties from different angles.</p>
      <Row>
          <Col>
            <Tool title="Google Analytics (GA)" icon="/images/logo/ga.svg" content="Deep dive into acqusition, content engagement &amp; conversions."/>  
            <Tool title="Adobe Analytics (AA)" icon="/images/logo/adobeanalytics.png" content="Study web analytics data together with integration to other data sources."/> 
            <Tool title="Mouseflow" icon="/images/logo/mouseflow.png" content="Study user on page scroll and click patterns via heatmaps."/>   
          </Col>
          <Col>
            <Tool title="Firebase Analytics" icon="/images/logo/firebase.png" content="Evaluate app acqusition channels &amp; in-app events. "/>  
            <Tool title="AppsFlyer" icon="/images/logo/appsflyer.png" content="Evaluate mobile ad network efficiencies &amp; in-app events."/>  
            <Tool title="Hotjar" icon="/images/logo/hotjar.png" content="Study user on page behaviour &amp; collect feedback."/>  
          </Col>
      </Row>
    </Container>
    <StyledBackground>
      <div className="py-5">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <Box style={{textAlign: 'left'}}>
                <h3 className="text-center">Contact Us</h3>
                <Hr/>
                <Form/>
              </Box>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledBackground>
  </Layout>
)
